.toggle {
  border-radius: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  text-align: right;
}

.navbar {
  background-color: #fff;
  height: 50px;
  display: grid;
  grid-template-columns: 200px 1fr 80px;
  justify-content: center;
  justify-items: center;
  // border-bottom: 1px solid var(--border-color);
  .site_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-size: cover;
  }
  .logo {
    height: 50px;
    position: absolute;
    left: 0;
  }
  .site_title {
    line-height: 50px;
    color: var(--text-main);
    font-weight: bold;
    font-size: 16px;
  }
  .user {
    height: 50px;
    line-height: 50px;
  }
}

.layout__content {
  flex: 1;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  background-color: var(--bg-color);
  padding-top: 2px;
}

.layout__main {
  padding: 10px 15px;
  height: calc(100vh - 52px);
  overflow-y: scroll;
}

@primary-color: #1083E6;